<template>
    <div class="dots">
        <div class="dot" v-for="el in num" :key="el" :style="{ 'animation-delay': `${0.06 * el}s`, 'background-color': color }"></div>
    </div>
</template>

<script>
export default {
    props: {
        num: Number,
        color: {
            type: String,
            default: '#0056ff',
        },
    },
};
</script>

<style lang="less" scoped>
.dots {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .dot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        // background-color: #0056ff;
        margin: 0 5px;
        animation: an 1s infinite;
        @keyframes an {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(2);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}
</style>
